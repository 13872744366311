import React from "react";
import axios from "axios";
import { AnyTxtRecord } from "dns";
import { hasData } from "jquery";
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { stat } from "fs"; 



//const API_URL = "https://groupnpay.com/vedagram/";
//const API_URL = "http://uat.atmagram.com/";
const API_URL = "https://www.atmagram.com/";

//const API_URL = "https://groupnpay.com/vedagram/";
//const API_URL = "http://192.168.3.166:8090/";
const share_URL = API_URL;

const googleMapsApiKey = "AIzaSyCU3S7JWcJQntMHoT3dMltNY4JGpZqnfWg";

const formatPhone = (phoneNo: any) => {
  const parsedNumber = parsePhoneNumber(phoneNo);
  const formatted = parsedNumber ? parsedNumber.formatInternational() : phoneNo
  return formatted;
};
const getApiUrl = () => {
  return share_URL;
}
const mapKey = () => {
  return googleMapsApiKey;
}

const myProfile = (user_id: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("user/myProfile?id=" + user_id)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const Profileupdate = (
  aadhar: any,
  address: any,
  pincode: any,
  area: any,
  city: any,
  country: any,
  email: any,
  firstname: any,
  lastname: any,
  phone: any,
  state: any,
  usertoken: any
) => {
  const logindata = {
    aadharNumber: aadhar,
    address: address + ',' + pincode,
    area: area,
    city: city,
    country: country,
    email: email,
    firstName: firstname,
    lastName: lastname,
    mobileNumber: phone,
    productType: null,
    state: state,
    vendorName: null,
  };

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("user/update", logindata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const ChangePassword = (
  newpassword: any,
  oldpassword: any,
  confirmpassword: any,
  usertoken: any
) => {
  const logindata = {
    password: newpassword,
    oldPassword: oldpassword,
    confirmPassword: confirmpassword,
  };

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("user/changePassword", logindata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
const ChangeNumber = (newNumber: any, usertoken: any) => {
  const numberBody: any = {
    "mobileNumber": newNumber
  };
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  console.log(numberBody, "Number")
  return api
    .post("user/changeMobileNumber", numberBody)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};


const Showallusers = (usertoken: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/showAllUsers")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const Showdeity = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("adm/showAllDeity")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}


const updateDeity = (usertoken: any, isedit: any, description: any, deityid: any, name: any, images: any) => {

  var url = "";
  var formdata: any = new FormData();
  if (isedit) {
    url = "updateDeity"
    formdata.append("deityDescription", description);
    formdata.append("id", deityid);
    formdata.append("deityName", name);
    if (images.length === 0) {

      formdata.append("deityImage", []);
    } else {
      const blobObj = new File([images], 'deityImage', { type: images.type });
      console.log(blobObj)

      formdata.append("deityImage", blobObj);
    }

  } else {
    url = "addDeity"
    formdata.append("deityName", name);
    formdata.append("deityDescription", description);
    formdata.append("deityImage", images);
  }
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });

  return api
    .post("adm/" + url, formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Viewtemple = (usertoken: any, tempId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("tempadm/getAllTemples?tempAdmId=" + tempId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Edittemple = (id: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .get("tempadm/viewTemple?templeId=" + id)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error;
    });
};

const updateTemple = (usertoken: any, templesdto: any, facilities: any, Offerings: any, festival: any, images: any, poojarow: any, templeVideoLinksDto: any, deleteImg: any, festivalId: any, offeringId: any, videoLinkId: any, tempId: any, liveVideoLinkid: any, poojaListId: any) => {
  var url = "";
  const formdata: any = new FormData();

  console.log(deleteImg, 'img')
  console.log(festivalId, "fesId")
  console.log(offeringId, "OffId")
  console.log(videoLinkId, "VideoId")
  console.log(poojaListId, "list Id")
  var obj = {
    "templesDto": templesdto,
    "facilitiesDto": facilities,
    "festivalsDto": festival,
    "poojaOfferingsDto": poojarow,
    "offeringsAndSignificanceDto": Offerings,
    "templeVideoLinksDto": templeVideoLinksDto,
    "templeImgsUrl": deleteImg,
    "festivalId": festivalId,
    "offeringAndSigId": offeringId,
    "videoLinkId": videoLinkId,
    "templeAdminId": tempId,
    "liveVideoLinkId": liveVideoLinkid,
    "poojaOfferingsId": poojaListId
  }
  console.log(images)
  // formdata.append('imgList',images)
  if (images.length > 0) {
    for (let i = 0; i < images.length; i++) {
      console.log(images[i])
      formdata.append('imgList', images[i])
    }
  } else {
    formdata.append('imgList', [])
  }

  const json = JSON.stringify(obj);
  console.log(json)
  const blobObj = new Blob([json], {
    type: 'application/json'
  });

  formdata.append('createTempleDto', blobObj)

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });

  return api
    .post("/tempadm/updateTemple", formdata)
    .then((response: any) => {
      console.log("redssss", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}


const createTemple = (usertoken: any, facilitiesDto: any, offeringsAndSignificanceDto: any, festivalsDto: any, templesDto: any, images: any, poojarow: any, videolink: any, lat: any, long: any, tempId: any) => {

  const formdata: any = new FormData();

  console.log(facilitiesDto)
  var obj = {
    "templesDto": templesDto,
    "facilitiesDto": facilitiesDto,
    "festivalsDto": festivalsDto,
    "poojaOfferingsDto": poojarow,
    "offeringsAndSignificanceDto": offeringsAndSignificanceDto,
    "templeVideoLinksDto": videolink,
    "tempLat": lat,
    "tempLong": long,
    "templeAdminId": tempId
  }
  console.log(images)
  // formdata.append('imgList',images)
  for (let i = 0; i < images.length; i++) {
    console.log(images[i])
    formdata.append('imgList', images[i])
  }
  const json = JSON.stringify(obj);
  console.log(json)
  const blobObj = new Blob([json], {
    type: 'application/json'
  });

  formdata.append('createTempleDto', blobObj)
  // formdata.append('createTempleDto',JSON.stringify(obj))


  console.log(formdata)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });

  return api
    .post("/tempadm/createTemple", formdata)
    .then((response: any) => {
      console.log("redssss", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const ShowdeitTemp = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("tempadm/showAllDeity")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Poojaofferings = (id: any, tid: any) => {
  console.log("jgcd", id, "jkhjhj", tid)
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/u/showPoojaOffering?deityId=" + id + "&tempId=" + tid)
    .then((response: any) => {
      console.log("res=>>>>", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const AddToCartInit = (details: any, usertoken: any, guestTkn: any) => {
  var api;

  if (usertoken == null) {
    api = axios.create({
      baseURL: API_URL,

    });
  } else {
    api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${usertoken}` },
    });
  }

  return api
    .post("/u/addToCartInit?guestToken=" + guestTkn, details)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const Payinitoffering = (details: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payInitPoojaOfferings", details)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const Payresoffering = (details: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payResPoojaOfferings", details)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const payInit = (usertoken: any, details: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payinitmultipoojaofferings")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const payOfferingRes = (usertoken: any, details: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payresmultipoojaofferings", details)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}


const MyOrder = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/u/myOrders")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Addcart = (usertoken: any, guestTkn: any) => {
  console.log()
  var api;

  if (usertoken != null && usertoken != undefined && usertoken != "") {
    api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${usertoken}` },
    });

  } else {
    api = axios.create({
      baseURL: API_URL,

    });

  }
  return api
    .post("/u/loadMyCart?guestToken=" + guestTkn)
    .then((response: any) => {
      console.log("res=>>>>", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const OrderTemp = (usertoken: any, id: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("tempadm/showAllOrders?tempAdmId=" + id)
    .then((response: any) => {
      console.log("res==>", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ShowAllOrder = (usertoken: any) => {
  console.log("kjg", usertoken)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/showAllOrders")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const removeCart = (usertoken: any, id: any) => {
  var api;

  if (usertoken != null && usertoken != undefined && usertoken != "") {
    api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${usertoken}` },
    });

  } else {
    api = axios.create({
      baseURL: API_URL,

    });

  }
  return api
    .post("/u/removeFromCart?vedaCartId=" + id)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Materialadm = (usertoken: any) => {
  console.log("kjg", usertoken)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/adm/showAllPoojaMaterial")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Viewmaterialadm = (usertoken: any, materialId: any) => {
  console.log("kjgkhk", usertoken)
  console.log("sjdf", materialId)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/adm/viewPoojaMaterial?materialId=" + materialId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const materialList = (usertoken: any, venId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/ven/showAllPoojaMaterial?vendorId=" + venId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const addMaterial = (usertoken: any, isedit: any, brand: any, price: any, name: any, images: any, pUnit: any, psize: any, stock: any, mid: any, quantity: any, venId: any) => {
  var url = "";
  var formdata: any = new FormData();
  console.log(name, brand, price, "un", pUnit, "si", psize, "i", images, "s", stock, "mid", mid)
  if (isedit) {
    url = "updatePoojaMaterial"
    formdata.append("id", mid);
    formdata.append("productName", name);
    formdata.append("brandName", brand);
    formdata.append("price", price);
    formdata.append("materialStock", stock);
    formdata.append("quantity", quantity);
    formdata.append("packageSize", psize);
    formdata.append("packageUnit", pUnit);
    formdata.append("vendorId", venId);
    if (images.length === 0) {
      formdata.append("materialImage", []);
    } else {
      const blobObj = new File([images], 'materialImage', { type: images.type });
      console.log(blobObj)
      formdata.append("materialImage", blobObj);
    }

  } else {
    url = "addPoojaMaterial"
    formdata.append("productName", name);
    formdata.append("brandName", brand);
    formdata.append("price", price);
    formdata.append("materialStock", stock);
    formdata.append("quantity", quantity);
    formdata.append("materialImage", images);
    formdata.append("packageSize", psize);
    formdata.append("packageUnit", pUnit);
    formdata.append("vendorId", venId);
  }
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });

  return api
    .post("ven/" + url, formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ShowAllPoojaMaterial = (id: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .get("u/showAllPoojaMaterial?templeId=" + id)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Grammerceadm = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/adm/showAllGrammerce")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Viewgrammerceadm = (usertoken: any, grammerceId: any) => {
  console.log("kjgkhk", usertoken)
  console.log("sjdf", grammerceId)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/adm/viewGrammerce?grammerceId=" + grammerceId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const updategrammerce = (usertoken: any, isedit: any, name: any, images: any, productname: any, price: any, significance: any, stock: any, gid: any, delleadtime: any, country: any, state: any, district: any) => {
  console.log(images, "qqqqqqqq1qq")

  var url = "";
  var formdata: any = new FormData();
  if (isedit) {
    url = "updateGrammerce"
    formdata.append("productName", productname);
    formdata.append("id", gid);
    formdata.append("brandName", name);
    formdata.append("price", price);
    formdata.append("significance", significance);
    formdata.append("stock", stock);
    formdata.append("deliveryLeadTime", delleadtime);
    formdata.append("country", country);
    formdata.append("state", state)
    formdata.append("district", district);



    if (images !== "") {
      // for (let i = 0; i < images.length; i++) {
      // console.log(images[i])
      formdata.append('grammerceImage', images)
      // }
    } else {
      formdata.append('grammerceImage', [])
    }

  } else {
    url = "addGrammerce"
    formdata.append("productName", productname);
    formdata.append("brandName", name);
    formdata.append("price", price);
    formdata.append("significance", significance);
    formdata.append("stock", stock);
    formdata.append("deliveryLeadTime", delleadtime);
    formdata.append("country", country);
    formdata.append("state", state)
    formdata.append("district", district);
    if (images !== "") {
      // for (let i = 0; i < images.length; i++) {
      // console.log(images[i])
      formdata.append('grammerceImage', images)
      // }
    } else {
      formdata.append('grammerceImage', [])
    }
  }
  console.log(formdata)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });

  return api
    .post("adm/" + url, formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ShowAllGrammerce = (pincode: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .get("u/showAllGrammerce?pincode=" + pincode)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const materialPayment = (data: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payinitmultipoojamaterials", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const materialPaymentMultiRes = (data: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payresmultipoojamaterials", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const materialOrderUser = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/u/myOrdersMaterials")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// const materialOrderUser = (usertoken: any) => {
//   const api = axios.create({
//     baseURL: API_URL,
//     headers: { Authorization: `Bearer ${usertoken}` },
//   });
//   return api
//     .get("/u/myOrdersMaterials")
//     .then((response: any) => {
//       return response;
//     })
//     .catch((error) => {
//       return error;
//     });
// }

const grammercePayment = (data: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payinitmultigrammerce", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const grammercePaymentMultiRes = (data: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payresmultigrammerce", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const materialorderList = (usertoken: any, id: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/ven/showAllOrders?vendorId=" + id)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const materialorderStatusList = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/ven/getMaterialStatusList")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Materialorderadm = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/adm/showAllMaterialsOrders")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Grammerceorder = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/u/myOrdersGrammerce")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const grammerceorders = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/adm/showAllGrammerceOrders")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const DonationPayment = (data: any) => {
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payinitdonate", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const DonationPaymentMultiRes = (data: any) => {
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payresdonate", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Donationmanage = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/adm/showAllDonations")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const DonationmanageSupport = () => {
  const api = axios.create({
    baseURL: API_URL,

  });
  return api
    .post("/adm/showAllDonations")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const DonationOrderUser = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/u/myDonations")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Showallproj = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/adm/showAllProjects")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Showallprojectadm = (usertoken: any, projAdm: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/projectadm/showAllProjects?projAdmId=" + projAdm)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Addproject = (usertoken: any, projecttitle: any, purpose: any, targetamt: any, startdate: any, enddate: any, minamt: any, maxamt: any, projAdmId: any) => {
  var formdata: any = {
    "projectTitle": projecttitle,
    "purpose": purpose,
    "targetAmount": targetamt,
    "startDate": startdate,
    "endDate": enddate,
    "minAmountDonate": minamt,
    "maxAmountDonate": maxamt,
    "projectAdminId": projAdmId
  }
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/projectadm/addNewProject", formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      alert("Project Not Added succesfully")
      return error;
    });
}
const Editproject = (usertoken: any, id: any, projecttitle: any, purpose: any, targetamt: any, startdate: any, enddate: any, minamt: any, maxamt: any, admId: any) => {
  var formdata: any = {
    "id": id,
    "projectTitle": projecttitle,
    "purpose": purpose,
    "targetAmount": targetamt,
    "startDate": startdate,
    "endDate": enddate,
    "minAmountDonate": minamt,
    "maxAmountDonate": maxamt,
    "projectAdminId": admId
  }
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/projectadm/updateProject", formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      alert("Project Not Added succesfully")
      return error;
    });
}
const ShowAllProjectUser = () => {
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .get("/u/showAllProjects")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ProjectDonationPayment = (data: any) => {
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payinitprojectDonate", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ProjectDonationPaymentMultiRes = (data: any) => {
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payresprojectDonate", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ProjectDonationOrderUser = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/u/myProjectDonations")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Donationadm = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/adm/showAllProjectDonations")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Projectdonation = (usertoken: any, id: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/projectadm/showAllProjectDonations?projAdmId=" + id)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ShowTempleAdmin = (usertoken: any, tempId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("adm/showAllTemples?templeAdminId=" + tempId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const calculateDelGrammerce = (usertoken: any, dataDel: any) => {
  var obj = JSON.stringify(dataDel);
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("u/calcDelcChargeForGrmmaerce", dataDel)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const calculateDelMaterial = (usertoken: any, dataDel: any) => {
  var obj = JSON.stringify(dataDel);
  const api = axios.create({
    baseURL: API_URL,
    //headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("u/calcDelcChargeForPoojaMaterials", dataDel)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const initShipForGrammerce = (usertoken: any, orderId: any, shDate: any, shTime: any,awb:any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/initShippmentForGrammrce?orderId=" + orderId + "&scheduleDt=" + shDate + "&scheduleTm=" + shTime +"&awbNumber="+awb)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const updateOrderStatusForGrammerce = (usertoken: any, data: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/changeGrammerceOrdersStatus", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const initShipForMaterial = (usertoken: any, orderId: any, shDate: any, shTime: any, shId: any,awb:any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("ven/initShippmentForPoojaMaterials?orderId=" + orderId + "&scheduleDt=" + shDate + "&scheduleTm=" + 
    shTime + "&statusId=" + shId+"&awbNumber=" + awb)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const updateOrderStatusForMaterial = (usertoken: any, data: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("ven/changeMaterialOrdersStatus", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const initShipForOffering = (usertoken: any, orderId: any, shDate: any, shTime: any, shId: any,awb:any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("tempadm/initShippmentForPoojaOfferings?orderId=" + orderId + "&scheduleDt=" + shDate + "&scheduleTm=" + 
    shTime + "&statusId=" + shId+"&awbNumber="+awb)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const updateOrderStatusForOffering = (usertoken: any, data: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("tempadm/changePoojaOfferingOrdersStatus", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const statusAdmUser = (usertoken: any, activeFlag: any, id: any, comm: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/actInact?id=" + id + "&activeFlag=" + activeFlag + "&comment=" + comm)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const statusVenMat = (usertoken: any, status: any, id: any, comment: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("ven/setPoojaMaterialFlag?materialId=" + id + "&activeFlag=" + status + "&comment=" + comment)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const statusAdmgram = (usertoken: any, status: any, id: any, comment: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/setGrammerceFlag?grammerceId=" + id + "&activeFlag=" + status + "&comment=" + comment)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const statusAdmtemp = (usertoken: any, activeFlag: any, id: any, comment: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/setTempleFlag?templeId=" + id + "&activeFlag=" + activeFlag + "&comment=" + comment)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const statusAdmproj = (usertoken: any, status: any, id: any, comment: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("adm/setProjectStatus?projectId=" + id + "&projectStatus=" + status + "&comment=" + comment)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const statusTempAdmTemple = (usertoken: any, status: any, id: any, comment: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("tempadm/setTempleFlag?templeId=" + id + "&activeFlag=" + status + "&comment=" + comment)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const calDelChargeForOffer = (usertoken: any, data: any) => {

  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("u/calcDelcChargeForOffering", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const pinValid = (pincode: any) => {
  const data = {
    "pincode": pincode
  }
  const api = axios.create({
    baseURL: API_URL
  });
  return api
    .post("u/pinForPoojaOff", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const pinValidGramm = (pincode: any, arr: any) => {
  const data = {
    "pincode": pincode,
    "grammerces": arr
  }
  const api = axios.create({
    baseURL: API_URL
  });
  return api
    .post("u/pincodeValid", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const changeOfferDone = (usertoken: any, id: any, flag: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/tempadm/offeringDone?id=" + id + "&poojaFlag=" + flag)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const Reciept = (usertoken: any, id: any, file: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  var formdata: any = new FormData();
  formdata.append("offeringId", id)
  formdata.append("receiptFile", file)
  return api
    .post("tempadm/uploadReceipt", formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const VerifyMobileChange = (token: any, userToken: any) => {
  var tkn = "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5NjAwMzA1NzY4IiwibW9iaWxlTnVtYmVyIjoiOTYwMDMwNTc2OCIsImVtYWlsIjoic2hhbm11Z2Fwcml5YUBpbmZvY2FyZWVyaW5kaWEuY29tIiwidXNlcklEIjoiNjQ0OTAwZDM5ZjczNzUyYzJjYzgyNDJmIiwicm9sZSI6IlJPTEVfVVNFUiIsImlhdCI6MTY4MjUwNjA4Nn0.4eCxwauvco2APdeyeXGvP2DpCUoU7QFmrOr-A5YUNn4";


  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });

  return api
    .get("user/changeNumberByTkn?resetNumToken=" + token)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
}
const templeAdmList = (usertoken: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("user/ROLE_TEMPADMIN/showAllUserByAdmin")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const vendorAdmList = (usertoken: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("user/ROLE_VENDOR/showAllUserByAdmin")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const projAdmList = (usertoken: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("user/ROLE_PROJECTADMIN/showAllUserByAdmin")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const deityActIn = (usertoken: any, id: any, activeFlag: any, comm: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/actInactDeity?id=" + id + "&activeFlag=" + activeFlag + "&comment=" + comm)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const tempadminsort = (usertoken: any, tempName: any, city: any, state: any, district: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/adm/showTempleAdm",
      {
        "templeName": tempName,
        "city": city,
        "country": "India",
        "state": state,
        "district": district
      })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getVendorList = (usertoken: any, state: any, city: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/showVendors", {
      "city": city,
      "country": "India",
      "state": state,
      // "district": city,
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getProjectAdminList = (usertoken: any, state: any, city: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/showProjectAdm", {
      "city": city,
      "country": "India",
      "state": state,
      // "district": city,
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getAllstates = () => {
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .get("/u/allStates")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getAllDistricts = (item: any) => {
  console.log(item)
  var body: any = {
    "state": item
  }
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/u/allDistricts", body)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const getAllDistrictsByState = (item: any) => {
  console.log(item)
  var body: any = {
    "state": item
  }
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/u/districtsByState", body)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const grammercecancel = (usertoken: any, id: any, reason: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("pay/cancelGrammerceOrder", {
      "grammerceOrdId": id,
      "cancelReason": reason
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const grammercereturn = (usertoken: any, id: any, pickupdate: any, pickuptime: any, reason: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("pay/returnGrammerceOrder", {
      "grammerceOrdId": id,
      "scheduleDate": pickupdate,
      "scheduleTime": pickuptime,
      "returnReason": reason
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const initRefundgrammerce = (usertoken: any, id: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("pay/initRefundForGrammerceOrder", {
      "grammerceOrdId": id,
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const grammercereplace = (usertoken: any, id: any, pickupdate: any, pickuptime: any, reason: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("pay/replaceGrammerceOrder", {
      "grammerceOrdId": id,
      "scheduleDate": pickupdate,
      "scheduleTime": pickuptime,
      "replaceReason": reason
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const poojaorderStatusList = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/tempadm/getPoojaStatusList")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const downLoadReport = (usertoken: any) => {
  const api = axios.create({
    baseURL: "http://192.168.0.13:8090/",
    headers: { Authorization: `Bearer ${usertoken}` },  });
  return api
    .get("projectadm/projectDonationReport")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const grammerceInvoice = (usertoken: any,paymentId:any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },  });
  return api
    .get("u/getInvoiceForOrder/grammerce?paymentId="+paymentId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const materialInvoice = (usertoken: any,paymentId:any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },  });
  return api
    .get("u/getInvoiceForOrder/material?paymentId="+paymentId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const donationInvoice = (usertoken: any,paymentId:any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },  });
  return api
    .get("u/getInvoiceForOrder/donation?id="+paymentId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const projdonationInvoice = (usertoken: any,paymentId:any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },  });
  return api
    .get("u/getInvoiceForOrder/projectDonation?id="+paymentId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const getAllRegion = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },  });
  return api
    .get("/adm/showAllRegions")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const downLoadGrammerceReport = (usertoken: any,data:any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },  });
  return api
    .post("/adm/grammerceOrdersReport",data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const downLoadDonationReport = (usertoken: any,data:any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },  });
  return api
    .post("/adm/donationsReport",data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const downLoadProjectDonationReport = (usertoken: any,data:any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },  });
  return api
    .post("/projectadm/projectDonationReport",data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const downLoadVendorReport = (usertoken: any,data:any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },  });
  return api
    .post("/ven/materialOrdersReport",data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const downLoadTempleReport = (usertoken: any,data:any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },  });
  return api
    .post("/tempadm/offeringsOrdersReport",data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Authservice = {
  myProfile,
  Profileupdate,
  ChangePassword,
  Showallusers,
  Showdeity,
  updateDeity,
  Viewtemple,
  Edittemple,
  updateTemple,
  ShowdeitTemp,
  createTemple,
  Poojaofferings,
  AddToCartInit,
  Addcart,
  payInit, MyOrder,
  OrderTemp,
  ShowAllOrder,
  removeCart,
  Materialadm,
  Viewmaterialadm,
  materialList,
  addMaterial,
  ShowAllPoojaMaterial,
  Grammerceadm,
  Viewgrammerceadm,
  updategrammerce,
  ShowAllGrammerce,
  materialPayment,
  materialOrderUser,
  grammercePayment,
  materialorderList,
  Grammerceorder,
  Materialorderadm,
  grammerceorders,
  DonationPayment,
  Donationmanage,
  DonationOrderUser,
  Showallprojectadm,
  Showallproj,
  Addproject, ShowAllProjectUser,
  ProjectDonationPayment,
  ProjectDonationOrderUser,
  Donationadm,
  Projectdonation,
  grammercePaymentMultiRes,
  materialPaymentMultiRes,
  DonationPaymentMultiRes,
  ProjectDonationPaymentMultiRes,
  payOfferingRes,
  DonationmanageSupport,
  ShowTempleAdmin,
  calculateDelGrammerce,
  calculateDelMaterial,
  initShipForGrammerce,
  updateOrderStatusForGrammerce,
  initShipForMaterial,
  updateOrderStatusForMaterial,
  initShipForOffering,
  updateOrderStatusForOffering,
  statusAdmUser,
  statusVenMat,
  statusAdmgram,
  statusAdmtemp,
  statusAdmproj,
  statusTempAdmTemple,
  Editproject,
  calDelChargeForOffer,
  changeOfferDone,
  Reciept,
  ChangeNumber,
  VerifyMobileChange,
  templeAdmList,
  vendorAdmList,
  projAdmList,
  Payinitoffering,
  Payresoffering,
  deityActIn,
  formatPhone,
  tempadminsort,
  getVendorList,
  getProjectAdminList,
  getAllstates,
  getAllDistricts,
  pinValid,
  pinValidGramm,
  getAllDistrictsByState,
  grammercecancel,
  grammercereturn,
  initRefundgrammerce,
  grammercereplace,
  getApiUrl,
  mapKey, materialorderStatusList,
  poojaorderStatusList,downLoadReport,
  grammerceInvoice,
  materialInvoice,
  donationInvoice,
  projdonationInvoice,
  getAllRegion,
  downLoadGrammerceReport,downLoadDonationReport,
  downLoadProjectDonationReport,
  downLoadVendorReport,
  downLoadTempleReport
  
};
export default Authservice;
